import { gql } from '@apollo/client';

export const LOGIN_ADMIN = gql`
    mutation loginAdmin($email: String!, $password: String!, $confirmationCode: String) {
        loginAdmin(email: $email, password: $password, confirmationCode: $confirmationCode) {
            admin {
                id
                active
                verified
                email
                fullName
                settings
                iconPath
            }
            accessToken
        }
    }
`;
export const REFRESH_TOKEN = gql`
    mutation refreshToken {
        refreshToken {
            accessToken
        }
    }
`;

export const LOGOUT_ADMIN = gql`
    mutation logoutAdmin {
        logoutAdmin
    }
`;

export const RESEND_ADMIN_VERIFICATION_EMAIL = gql`
    mutation resendAdminEmailVerification($email: String) {
        resendAdminEmailVerification(email: $email)
    }
`;
