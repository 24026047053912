import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Col, Row, Spinner, Label, FormGroup, Input } from 'reactstrap';
import { MaterialSymbol } from 'react-material-symbols';

import {
    GET_RECIPE_TO_TRANSLATE,
    UPDATE_RECIPE_TRANSLATION,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';
import { REST_URL } from 'configs/restAPI';
import axios from 'axios';
import RecipeTranslationVideo from './video';
import RecipeTranslationGenerator from './generatedTranslation';
import ReactPlayer from 'react-player';

export default function RecipeTranslationDetail() {
    const { recipeID } = useParams();

    const [adminUpdateRecipeTranslation] = useMutation(UPDATE_RECIPE_TRANSLATION);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const {
        data: recipeData,
        loading: recipeLoading,
        refetch: recipeRefetch,
    } = useQuery(GET_RECIPE_TO_TRANSLATE, {
        variables: {
            recipeId: parseInt(recipeID),
        },
        fetchPolicy: 'network-only',
    });

    const [translationWasEdited, setTranslationWasEdited] = useState(false);
    const [originalTranslation, setOriginalTranslation] = useState(null);
    const [originalSubtitles, setOriginalSubtitles] = useState([]);
    const [loadingOriginalSubtitles, setLoadingOriginalSubtitles] = useState(false);

    const [allTranslations, setAllTranslations] = useState([]);

    const [saving, setSaving] = useState(false);

    const [languageTab, setLanguageTab] = useState(null);
    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        recipeRefetch({
            recipeId: parseInt(recipeID),
        });
    }, [recipeID]);

    useEffect(() => {
        if (recipeData && recipeData.recipe) {
            const newOriginalTranslation = recipeData.recipe.recipeTranslations.find(
                (translation) => translation.def
            );
            setOriginalTranslation({
                ...newOriginalTranslation,
                videoPath: recipeData.recipe.videoPath,
                preparation: [...newOriginalTranslation.preparation].sort((p1, p2) =>
                    p1.order < p2.order ? -1 : 1
                ),
            });

            setLoadingOriginalSubtitles(true);
            getSubtitles(
                recipeData.recipe.recipeSubtitles.find(
                    (sub) => sub.language.id === newOriginalTranslation.language.id
                )?.path
            );
        }
    }, [recipeData]);

    useEffect(() => {
        if (!languagesData || !languagesData.languages || !recipeData || !recipeData.recipe) {
            setLanguageOptions([]);
        } else {
            const newLanguageOptions = [...languagesData.languages]
                .filter((lang) =>
                    originalTranslation ? originalTranslation.language.id !== lang.id : true
                )
                .sort((lang1, lang2) => (lang1.languageCode > lang2.languageCode ? 1 : -1))
                .map((lang) => ({
                    ...lang,
                    value: lang.id,
                    label: lang.languageCode.toUpperCase(),
                    done: originalTranslation
                        ? recipeData.recipe.recipeTranslations.find(
                              (translation) => translation.language.id === lang.id
                          )?.done ?? false
                        : false,
                }));

            setLanguageOptions(newLanguageOptions);
            let newAllTranslations = newLanguageOptions.map((lang) => ({
                language: lang,
                translation: recipeData.recipe.recipeTranslations.find(
                    (translation) => translation.language.id === lang.id
                ),
                subtitlesPath: recipeData.recipe.recipeSubtitles.find(
                    (sub) => sub.language.id === lang.id
                )?.path,
            }));
            setAllTranslations(newAllTranslations);
        }
    }, [originalTranslation, languagesData, recipeData]);

    async function getSubtitles(subtitlesPath) {
        if (!subtitlesPath) {
            setOriginalSubtitles([]);
            setLoadingOriginalSubtitles(false);
            return;
        }

        try {
            //  const srtFile = await fetch(`${REST_URL}/${subtitlesPath}`);
            const srtFile = await axios.get(`${REST_URL}/${subtitlesPath}`, {
                params: {
                    responseType: 'blob', // Important
                    //    mode: 'no-cors',
                },
                /*       headers: {
                    //        'Authorization': `${sessionStorage.getItem('acctok')}`,
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': 'http://localhost:4000',
                },*/
            });
            const parsedSubtitles = srtFile.data.split(/[\r\n]+/);

            /*  const parsedSubtitles = `1
                00:05:00,400 --> 00:05:15,300
                This is an example of
                a subtitle.

                2
                00:05:16,400 --> 00:05:25,300
                This is an example of
                a subtitle - 2nd subtitle.`.split(/[\r\n]+/);*/
            let result = [];
            let nextEntry = null;
            for (let index = 0; index < parsedSubtitles.length; index++) {
                const element = parsedSubtitles[index].trim();
                if (/^[0-9]+$/g.test(element)) {
                    if (nextEntry) {
                        result.push(nextEntry);
                    }
                    nextEntry = {
                        order: element,
                        timeStamp: null,
                        text: '',
                    };
                } else if (
                    /[0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}.{1,}[0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}/.test(
                        element
                    )
                ) {
                    nextEntry.timeStamp = element;
                } else {
                    nextEntry.text = nextEntry.text + ' ' + element;
                }
            }
            result.push(nextEntry);
            setOriginalSubtitles(result);
            setLoadingOriginalSubtitles(false);
        } catch (e) {
            //console.log(e);
            setOriginalSubtitles([]);
            setLoadingOriginalSubtitles(false);
        }
    }

    const currentTranslation = useMemo(() => {
        if (!languageTab) {
            return null;
        }
        return allTranslations.find((translation) => translation.language.id === languageTab.id);
    }, [allTranslations, languageTab]);

    const updateRecipeDone = () => {
        setSaving(true);

        adminUpdateRecipeTranslation({
            variables: {
                recipeId: parseInt(recipeID),
                languageId: parseInt(languageTab.id),
                done: !currentTranslation.done,
            },
        }).then((resp) => {
            let newAllTranslations = allTranslations.map((translation) => ({
                ...translation,
                translation:
                    translation.language.id === languageTab.id
                        ? {
                              ...translation.translation,
                              done: !currentTranslation.done,
                          }
                        : translation.translation,
            }));
            setAllTranslations(newAllTranslations);

            setLanguageOptions(
                languageOptions.map((option) =>
                    option.id === languageTab.id ? { ...option, done: !option.done } : option
                )
            );
            setTranslationWasEdited(false);
            setSaving(false);
        });
    };

    if (recipeLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Row xs="2">
                <Col>
                    <h1>{`Originál - ${originalTranslation?.language.languageCode.toUpperCase()}`}</h1>
                </Col>
                <Col>
                    <Row
                        style={{
                            marginBottom: '1em',
                            cursor: 'pointer',
                        }}
                    >
                        {languageOptions.map((language) => (
                            <Col
                                key={language.id}
                                className="flex jc-center"
                                style={
                                    languageTab?.id === language.id
                                        ? {
                                              fontWeight: 'bold',
                                              paddingBottom: '1em',
                                              borderBottom: '2px solid lavender',
                                          }
                                        : { paddingBottom: '1em' }
                                }
                                onClick={() => {
                                    if (translationWasEdited) {
                                        if (
                                            window.confirm(
                                                'Ste si istý, že chcete prejsť na iný jazyk? Neuložené zmeny nebudú zachované.'
                                            )
                                        ) {
                                            setLanguageTab(language);
                                            setTranslationWasEdited(false);
                                        }
                                    } else {
                                        setLanguageTab(language);
                                        setTranslationWasEdited(false);
                                    }
                                    const file = document.querySelector('#translation-video');
                                    if (file) {
                                        file.value = '';
                                    }
                                }}
                            >
                                {language.label}
                                {language.done && (
                                    <MaterialSymbol
                                        icon={'verified'}
                                        size={24}
                                        grade={-25}
                                        color={'green'}
                                        style={{
                                            outline: 'none',
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                    />
                                )}
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        {saving && <Spinner />}
                        {!saving && languageTab && (
                            <FormGroup check>
                                <Input
                                    id="current-translation-done"
                                    type="checkbox"
                                    checked={currentTranslation?.translation?.done ? true : false}
                                    onChange={() => {
                                        if (
                                            window.confirm(
                                                currentTranslation?.translation?.done
                                                    ? 'Ste si istý, že chcete tento preklad označiť ako nedokončený?'
                                                    : 'Ste si istý, že boli preložené všetky potrebné časti receptu?'
                                            )
                                        ) {
                                            updateRecipeDone();
                                        }
                                    }}
                                />
                                <Label check htmlFor="current-translation-done">
                                    Preklad do tohto jazyka je dokončený.
                                </Label>
                            </FormGroup>
                        )}
                    </Row>
                </Col>
            </Row>

            <hr />

            <Row xs="2">
                <Col>
                    <Row>
                        <Label>Názov</Label>
                        <p>{originalTranslation?.title ?? 'Bez názvu'}</p>
                    </Row>
                    <Row>
                        <Label>Popis</Label>
                        <p>
                            {!originalTranslation ||
                            !originalTranslation.description ||
                            originalTranslation.description.length === 0
                                ? 'Bez popisu'
                                : originalTranslation.description}
                        </p>
                    </Row>
                    <Row>
                        <Label>Postup</Label>
                        {(!originalTranslation ||
                            !originalTranslation.preparation ||
                            originalTranslation.preparation.length === 0) && <p>Bez postupu</p>}
                        {originalTranslation &&
                            originalTranslation.preparation &&
                            [...originalTranslation.preparation].map((prep, index) => (
                                <p key={index}>
                                    <label>{`Krok ${index + 1} `}</label>
                                    {prep.text}
                                </p>
                            ))}
                    </Row>
                    <Row>
                        <Label style={{ display: 'block' }}>Titulky</Label>
                        {loadingOriginalSubtitles && <Spinner />}
                        {!loadingOriginalSubtitles && originalSubtitles.length === 0 && (
                            <p>Bez titulkov</p>
                        )}
                        {!loadingOriginalSubtitles &&
                            originalSubtitles.map((subtitle) => (
                                <div key={subtitle.order}>
                                    <label
                                        style={{ display: 'block' }}
                                    >{`${subtitle.order} | ${subtitle.timeStamp}`}</label>
                                    <p>{subtitle.text}</p>
                                </div>
                            ))}
                    </Row>
                </Col>
                <Col>
                    {!languageTab && (
                        <p style={{ textAlign: 'center' }}>Prosím vyberte jazyk prekladu.</p>
                    )}
                    {languageTab && (
                        <RecipeTranslationGenerator
                            recipeId={parseInt(recipeID)}
                            language={languageTab}
                            setTranslationWasEdited={setTranslationWasEdited}
                            originalTranslation={originalTranslation}
                            originalSubtitles={originalSubtitles}
                            recipeTranslation={currentTranslation?.translation}
                            recipeSubtitlesPath={currentTranslation?.subtitlesPath}
                            allTranslations={allTranslations}
                            setAllTranslations={setAllTranslations}
                        />
                    )}
                </Col>
            </Row>

            <hr />

            <Row xs="2">
                <Col>
                    <Label>Video</Label>
                    <ReactPlayer
                        url={[`${REST_URL}/${originalTranslation?.videoPath}`]}
                        controls={true}
                        width={'100%'}
                    />
                </Col>
                <Col>
                    {languageTab && (
                        <RecipeTranslationVideo
                            recipeId={parseInt(recipeID)}
                            languageId={parseInt(languageTab.id)}
                            languageLabel={languageTab.label}
                            originalVideo={currentTranslation?.translation?.video}
                            allTranslations={allTranslations}
                            setAllTranslations={setAllTranslations}
                            canUploadVideo={
                                ![null, undefined].includes(currentTranslation?.translation)
                            }
                            recipeRefetch={() => {
                                recipeRefetch({
                                    recipeId: parseInt(recipeID),
                                });
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Col>
    );
}
