import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import CuisineForm from './form';
import { MaterialSymbol } from 'react-material-symbols';
import DeleteCuisineForm from './deleteForm';

export default function Cuisines(props) {
    const { cuisines, languages, onCreateCuisine, onUpdateCuisine, onRemoveCuisine } = props;

    const [editCuisine, setEditCuisine] = useState(null);
    const [deleteCuisine, setDeleteCuisine] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const sortedCuisines = [...cuisines].sort((i1, i2) => (i1.order < i2.order ? -1 : 1));

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        {languages.map((lang) => (
                            <th key={lang.value}>{lang.label}</th>
                        ))}
                        <th width={'5%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-cuisine-btn">
                        <td
                            colSpan={1 + languages.length}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setEditCuisine(null);
                                toggle();
                            }}
                        >
                            + Diéta
                        </td>
                    </tr>
                    {sortedCuisines.map((cuisine) => (
                        <tr
                            key={cuisine.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {languages.map((lang) => (
                                <td key={`${cuisine.id}-${lang.value}`}>
                                    {cuisine.translations.find(
                                        (translation) => translation.language.id === lang.value
                                    )?.title ?? '-'}
                                </td>
                            ))}

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <MaterialSymbol
                                            icon={'edit'}
                                            size={24}
                                            grade={-25}
                                            color={'orange'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                setEditCuisine(cuisine);
                                                toggle();
                                            }}
                                        />
                                    </Col>
                                    {cuisines.length >= 2 && (
                                        <Col>
                                            <MaterialSymbol
                                                icon={'cancel'}
                                                size={24}
                                                grade={-25}
                                                color={'red'}
                                                style={{
                                                    outline: 'none',
                                                    border: 'none',
                                                    backgroundColor: 'transparent',
                                                }}
                                                onClick={() => {
                                                    setDeleteCuisine(cuisine);
                                                    toggle();
                                                }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditCuisine(null);
                        setDeleteCuisine(null);
                    }
                    toggle();
                }}
            >
                {deleteCuisine && (
                    <DeleteCuisineForm
                        onClose={() => {
                            setEditCuisine(null);
                            setDeleteCuisine(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            if (window.confirm('Ste si isty?')) {
                                onRemoveCuisine(data);
                                setDeleteCuisine(null);
                                toggle();
                            }
                        }}
                        cuisine={deleteCuisine}
                        replacementOptions={cuisines
                            .filter((cuisine) => cuisine.id !== deleteCuisine.id)
                            .map((cuisine) => ({
                                value: cuisine.id,
                                label: cuisine.translations
                                    .map(
                                        (translation) =>
                                            `${translation.title} (${translation.language.languageCode})`
                                    )
                                    .join(', '),
                            }))}
                    />
                )}
                {!deleteCuisine && (
                    <CuisineForm
                        onClose={() => {
                            setEditCuisine(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            if (editCuisine) {
                                onUpdateCuisine(data);
                            } else {
                                onCreateCuisine(data);
                            }
                            setEditCuisine(null);
                            toggle();
                        }}
                        cuisine={editCuisine}
                        languages={languages}
                    />
                )}
            </Modal>
        </>
    );
}
