import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import MealTypeForm from './form';
import { MaterialSymbol } from 'react-material-symbols';

export default function MealTypes(props) {
    const { mealTypes, languages, onCreateMealType, onUpdateMealType, onRemoveMealType } = props;

    const [editMealType, setEditMealType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const sortedMealTypes = [...mealTypes].sort((i1, i2) => (i1.order < i2.order ? -1 : 1));

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        {languages.map((lang) => (
                            <th key={lang.value}>{lang.label}</th>
                        ))}
                        <th width={'5%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-mealType-btn">
                        <td
                            colSpan={1 + languages.length}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setEditMealType(null);
                                toggle();
                            }}
                        >
                            + Typ jedla
                        </td>
                    </tr>
                    {sortedMealTypes.map((mealType) => (
                        <tr
                            key={mealType.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {languages.map((lang) => (
                                <td key={`${mealType.id}-${lang.value}`}>
                                    {mealType.translations.find(
                                        (translation) => translation.language.id === lang.value
                                    )?.title ?? '-'}
                                </td>
                            ))}

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <MaterialSymbol
                                            icon={'edit'}
                                            size={24}
                                            grade={-25}
                                            color={'orange'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                setEditMealType(mealType);
                                                toggle();
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <MaterialSymbol
                                            icon={'cancel'}
                                            size={24}
                                            grade={-25}
                                            color={'red'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                if (window.confirm('Ste si isty?')) {
                                                    onRemoveMealType(mealType.id);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditMealType(null);
                    }
                    toggle();
                }}
            >
                <MealTypeForm
                    onClose={() => {
                        setEditMealType(null);
                        toggle();
                    }}
                    onSave={(data) => {
                        if (editMealType) {
                            onUpdateMealType(data);
                        } else {
                            onCreateMealType(data);
                        }
                        setEditMealType(null);
                        toggle();
                    }}
                    mealType={editMealType}
                    languages={languages}
                />
            </Modal>
        </>
    );
}
