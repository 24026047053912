import axios from 'axios';
import { REST_URL } from 'configs/restAPI';

//refreshne access a refresh token
export default async function refreshToken() {
    return axios.request({
        url: `${REST_URL}/graphql?query=mutation+refreshToken%7B%0A++++++++refreshToken%7B%0A++++++++++++accessToken%0A++++++++%7D%0A++++%7D`,
        method: 'post',
        withCredentials: true,
    });
}
