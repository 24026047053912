import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Cuisines from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    CUISINES,
    CUISINES_SUBSCRIPTION,
    ADD_CUISINE,
    UPDATE_CUISINE,
    DELETE_CUISINE,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

export default function CuisinesContainer() {
    const [addCuisine] = useMutation(ADD_CUISINE);
    const [updateCuisine] = useMutation(UPDATE_CUISINE);
    const [deleteCuisine] = useMutation(DELETE_CUISINE);

    const {
        data: cuisinesData,
        loading: cuisinesLoading,
        refetch: cuisinesRefetch,
    } = useQuery(CUISINES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CUISINES_SUBSCRIPTION, {
        onData: () => {
            cuisinesRefetch();
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const onCreateCuisine = (data) => {
        addCuisine({
            variables: {
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };

    const onUpdateCuisine = (data) => {
        updateCuisine({
            variables: {
                updateCuisineId: data.id,
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };
    const onRemoveCuisine = (data) => {
        deleteCuisine({
            variables: {
                deleteCuisineId: data.id,
                replacementId: data.replacementId,
            },
        });
    };

    if (cuisinesLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Cuisines
                cuisines={cuisinesData && cuisinesData.cuisines ? cuisinesData.cuisines : []}
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreateCuisine={onCreateCuisine}
                onUpdateCuisine={onUpdateCuisine}
                onRemoveCuisine={onRemoveCuisine}
            />
        </Col>
    );
}
