import { gql } from '@apollo/client';

export const CUISINES_SUBSCRIPTION = gql`
    subscription cuisinesSubscription {
        cuisinesSubscription
    }
`;

export const CUISINES = gql`
    query cuisines {
        cuisines {
            available
            id
            order
            translations {
                title
                language {
                    id
                    languageCode
                }
            }
        }
    }
`;

export const ADD_CUISINE = gql`
    mutation addCuisine(
        $available: Boolean
        $order: Int
        $translations: [CuisineTranslationInput!]!
    ) {
        addCuisine(available: $available, order: $order, translations: $translations) {
            id
        }
    }
`;

export const UPDATE_CUISINE = gql`
    mutation updateCuisine(
        $updateCuisineId: Int!
        $available: Boolean
        $translations: [CuisineTranslationInput!]!
        $order: Int
    ) {
        updateCuisine(
            id: $updateCuisineId
            available: $available
            translations: $translations
            order: $order
        ) {
            id
        }
    }
`;

export const DELETE_CUISINE = gql`
    mutation deleteCuisine($deleteCuisineId: Int!, $replacementId: Int!) {
        deleteCuisine(id: $deleteCuisineId, replacementId: $replacementId)
    }
`;
