import { gql } from '@apollo/client';

export const USERS_SUBSCRIPTION = gql`
    subscription usersSubscription {
        usersSubscription
    }
`;

export const USERS = gql`
    query users {
        users {
            id
            fullName
            email
            active
            verified
        }
    }
`;

export const GET_USER = gql`
    query user($id: Int!) {
        user(id: $id) {
            id
            active
            verified
            email
            name
            surname
            fullName
            phoneNumber
            notificationsThroughEmail
            notificationsThroughSMS
            iconPath
            applicationLanguage {
              id
            }
            videoLanguage {
              id
            }
            subtitleLanguage {
              id
            }
        }
    }
`;

export const REGISTER_USER = gql`
    mutation registerUser(
        $email: String!
        $password: String!
        $name: String!
        $surname: String!
        $phoneNumber: String!
        $notificationsThroughEmail: Boolean
        $notificationsThroughSMS: Boolean
        $icon: String

        $languageId: Int!

        $addresses: [AddressInput!]
    ) {
        registerUser(
            email: $email
            password: $password
            name: $name
            surname: $surname
            phoneNumber: $phoneNumber
            languageId: $languageId
            notificationsThroughEmail: $notificationsThroughEmail
            notificationsThroughSMS: $notificationsThroughSMS
            icon: $icon
            addresses: $addresses
        ) {
            id
        }
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser(
        $id: Int!
        $name: String
        $surname: String
        $phoneNumber: String
        $notificationsThroughEmail: Boolean
        $notificationsThroughSMS: Boolean
        $icon: String
        $email: String
        $password: String
        $applicationLanguageId: Int
        $videoLanguageId: Int
        $subtitleLanguageId: Int
    ) {
        updateUser(
            id: $id
            name: $name
            phoneNumber: $phoneNumber
            surname: $surname
            notificationsThroughEmail: $notificationsThroughEmail
            notificationsThroughSMS: $notificationsThroughSMS
            icon: $icon
            email: $email
            password: $password
            applicationLanguageId: $applicationLanguageId
            videoLanguageId: $videoLanguageId
            subtitleLanguageId: $subtitleLanguageId
        ) {
            id
        }
    }
`;

export const CHANGE_USER_ACTIVE = gql`
    mutation changeUserActive($id: Int) {
        changeUserActive(id: $id) {
            id
        }
    }
`;
