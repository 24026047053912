import { gql } from '@apollo/client';

export const PREPARATOIN_TIMES_SUBSCRIPTION = gql`
    subscription preparationTimesSubscription {
        preparationTimesSubscription
    }
`;

export const PREPARATOIN_TIMES = gql`
    query preparationTimes($available: Boolean) {
        preparationTimes(available: $available) {
            available
            id
            order
            translations {
                title
                language {
                    id
                }
            }
        }
    }
`;

export const ADD_PREPARATOIN_TIME = gql`
    mutation addPreparationTime(
        $available: Boolean
        $order: Int
        $translations: [PreparationTimeTranslationInput!]!
    ) {
        addPreparationTime(available: $available, order: $order, translations: $translations) {
            id
        }
    }
`;

export const UPDATE_PREPARATOIN_TIME = gql`
    mutation updatePreparationTime(
        $updatePreparationTimeId: Int!
        $available: Boolean
        $translations: [PreparationTimeTranslationInput!]!
        $order: Int
    ) {
        updatePreparationTime(
            id: $updatePreparationTimeId
            available: $available
            translations: $translations
            order: $order
        ) {
            id
        }
    }
`;

export const DELETE_PREPARATOIN_TIME = gql`
    mutation deletePreparationTime($deletePreparationTimeId: Int!) {
        deletePreparationTime(id: $deletePreparationTimeId)
    }
`;
