import React, { useState } from 'react';

import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
} from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';

import { useGetMyData } from 'utils';
import { LOGOUT_ADMIN } from 'queries';

import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import AdminProfile from 'pages/admins/profile';
import Image from 'components/image';

export default function PageHeader() {
    const navigate = useNavigate();
    const currentAdmin = useGetMyData();

    const [logoutAdmin] = useMutation(LOGOUT_ADMIN);

    const [adminPopoverOpen, setAdminPopoverOpen] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    return (
        <Row style={{ marginBottom: '2em' }}>
            <Col>
                <Nav fill pills>
                    {/*
                    <NavItem>
                        <NavLink tag={Link} to="/stores">
                            Obchody
                        </NavLink>
                    </NavItem>
                    */}
                    <NavItem>
                        <NavLink tag={Link} to="/customers">
                            Zákazníci
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/chefs">
                            Šéfkuchári
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/potential-chefs">
                            Žiadosti šéfkuchárov
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/recipe-translations">
                            Preklady receptov
                        </NavLink>
                    </NavItem>
                    {currentAdmin && currentAdmin.settings && (
                        <NavItem>
                            <NavLink tag={Link} to="/settings">
                                Nastavenia
                            </NavLink>
                        </NavItem>
                    )}
                    {currentAdmin && currentAdmin.settings && (
                        <NavItem>
                            <NavLink tag={Link} to="/system">
                                Systém
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            </Col>
            <Col className="flex jc-flex-end">
                <Dropdown
                    isOpen={adminPopoverOpen}
                    toggle={() => setAdminPopoverOpen(!adminPopoverOpen)}
                    direction="down"
                >
                    <DropdownToggle caret color="link" className="flex">
                        <Image
                            path={currentAdmin ? currentAdmin.iconPath : null}
                            label={currentAdmin ? currentAdmin.fullName : 'Unnamed admin'}
                            size={'icon'}
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => toggle()}>Profil</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                            onClick={() => {
                                logoutAdmin().then(() => {
                                    navigate(`/`);
                                    window.location.reload(false);
                                });
                            }}
                        >
                            Odhlásiť sa
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                <Modal isOpen={modalOpen} toggle={toggle}>
                    <AdminProfile
                        onClose={() => {
                            toggle();
                        }}
                        onSave={() => {
                            toggle();
                        }}
                    />
                </Modal>
            </Col>
        </Row>
    );
}
