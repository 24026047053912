import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import DietForm from './form';
import { MaterialSymbol } from 'react-material-symbols';

export default function Diets(props) {
    const { diets, languages, onCreateDiet, onUpdateDiet, onRemoveDiet } = props;

    const [editDiet, setEditDiet] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const sortedDiets = [...diets].sort((i1, i2) => (i1.order < i2.order ? -1 : 1));

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        {languages.map((lang) => (
                            <th key={lang.value}>{lang.label}</th>
                        ))}
                        <th width={'5%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-diet-btn">
                        <td
                            colSpan={1 + languages.length}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setEditDiet(null);
                                toggle();
                            }}
                        >
                            + Diéta
                        </td>
                    </tr>
                    {sortedDiets.map((diet) => (
                        <tr
                            key={diet.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {languages.map((lang) => (
                                <td key={`${diet.id}-${lang.value}`}>
                                    {diet.translations.find(
                                        (translation) => translation.language.id === lang.value
                                    )?.title ?? '-'}
                                </td>
                            ))}

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <MaterialSymbol
                                            icon={'edit'}
                                            size={24}
                                            grade={-25}
                                            color={'orange'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                setEditDiet(diet);
                                                toggle();
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <MaterialSymbol
                                            icon={'cancel'}
                                            size={24}
                                            grade={-25}
                                            color={'red'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                if (window.confirm('Ste si isty?')) {
                                                    onRemoveDiet(diet.id);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditDiet(null);
                    }
                    toggle();
                }}
            >
                <DietForm
                    onClose={() => {
                        setEditDiet(null);
                        toggle();
                    }}
                    onSave={(data) => {
                        if (editDiet) {
                            onUpdateDiet(data);
                        } else {
                            onCreateDiet(data);
                        }
                        setEditDiet(null);
                        toggle();
                    }}
                    diet={editDiet}
                    languages={languages}
                />
            </Modal>
        </>
    );
}
