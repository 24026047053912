import { setContext } from '@apollo/client/link/context';

import * as fns from 'date-fns';
import jwtDecode from 'jwt-decode';

import { setIsLoggedIn } from '../localSchema/actions';
import getRefreshToken from 'apollo/refreshToken';

const afterNow = (unix) => {
    return unix > fns.getUnixTime(new Date());
};

export const authLink = setContext(async (_, { headers }) => {
    let token = sessionStorage.getItem('acctok');

    if (!token) {
        return headers;
    }

    if (!afterNow(jwtDecode(token).exp)) {
        const { refreshToken } = (await getRefreshToken()).data;

        if (refreshToken) {
            token = refreshToken.accessToken;
            sessionStorage.setItem('acctok', token);
        } else {
            sessionStorage.removeItem('acctok');
            setIsLoggedIn(false);
        }
    }

    return {
        headers: {
            ...headers,
            Authorization: token,
        },
    };
});
