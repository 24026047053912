import { gql } from '@apollo/client';

export const MEAL_TYPES_SUBSCRIPTION = gql`
    subscription mealTypesSubscription {
        mealTypesSubscription
    }
`;

export const MEAL_TYPES = gql`
    query mealTypes($available: Boolean) {
        mealTypes(available: $available) {
            available
            id
            order
            translations {
                title
                language {
                    id
                }
            }
        }
    }
`;

export const ADD_MEAL_TYPE = gql`
    mutation addMealType(
        $available: Boolean
        $order: Int
        $translations: [MealTypeTranslationInput!]!
    ) {
        addMealType(available: $available, order: $order, translations: $translations) {
            id
        }
    }
`;

export const UPDATE_MEAL_TYPE = gql`
    mutation updateMealType(
        $updateMealTypeId: Int!
        $available: Boolean
        $translations: [MealTypeTranslationInput!]!
        $order: Int
    ) {
        updateMealType(
            id: $updateMealTypeId
            available: $available
            translations: $translations
            order: $order
        ) {
            id
        }
    }
`;

export const DELETE_MEAL_TYPE = gql`
    mutation deleteMealType($deleteMealTypeId: Int!) {
        deleteMealType(id: $deleteMealTypeId)
    }
`;
