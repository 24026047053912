import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import MealTypes from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    MEAL_TYPES,
    MEAL_TYPES_SUBSCRIPTION,
    ADD_MEAL_TYPE,
    UPDATE_MEAL_TYPE,
    DELETE_MEAL_TYPE,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

export default function MealTypesContainer() {
    const [addMealType] = useMutation(ADD_MEAL_TYPE);
    const [updateMealType] = useMutation(UPDATE_MEAL_TYPE);
    const [deleteMealType] = useMutation(DELETE_MEAL_TYPE);

    const {
        data: mealTypesData,
        loading: mealTypesLoading,
        refetch: mealTypesRefetch,
    } = useQuery(MEAL_TYPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(MEAL_TYPES_SUBSCRIPTION, {
        onData: () => {
            mealTypesRefetch();
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const onCreateMealType = (data) => {
        addMealType({
            variables: {
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };

    const onUpdateMealType = (data) => {
        updateMealType({
            variables: {
                updateMealTypeId: data.id,
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };
    const onRemoveMealType = (id) => {
        deleteMealType({
            variables: {
                deleteMealTypeId: id,
            },
        });
    };

    if (mealTypesLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <MealTypes
                mealTypes={mealTypesData && mealTypesData.mealTypes ? mealTypesData.mealTypes : []}
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreateMealType={onCreateMealType}
                onUpdateMealType={onUpdateMealType}
                onRemoveMealType={onRemoveMealType}
            />
        </Col>
    );
}
