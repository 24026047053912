import { split, HttpLink, ApolloLink, from as ApolloFrom } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { REST_URL, SOCKET_URL } from 'configs/restAPI';

const httpLink = new HttpLink({
    uri: `${REST_URL}/graphql`,
    credentials: 'include',
});

const addHeader = new ApolloLink((operation, forward) => {
    try {
        operation.extensions = {
            headers: {
                Authorization: `${sessionStorage.getItem('acctok')}`,
            },
        };
    } catch (e) {
        console.log(e);
    }
    return forward(operation);
});

const socketLink = new GraphQLWsLink(
    createClient({
        url: `${SOCKET_URL}/graphql`,
        options: {
            reconnect: true,
        },
        connectionParams: () => ({
            Authorization: `${sessionStorage.getItem('acctok')}`,
            extensions: {
                headers: {
                    Authorization: `${sessionStorage.getItem('acctok')}`,
                },
            },
        }),
    })
);

export const connectionLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    ApolloFrom([addHeader, socketLink]),
    httpLink
);
