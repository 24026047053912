import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import PreparationTimes from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    PREPARATOIN_TIMES,
    PREPARATOIN_TIMES_SUBSCRIPTION,
    ADD_PREPARATOIN_TIME,
    UPDATE_PREPARATOIN_TIME,
    DELETE_PREPARATOIN_TIME,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

export default function PreparationTimesContainer() {
    const [addPreparationTime] = useMutation(ADD_PREPARATOIN_TIME);
    const [updatePreparationTime] = useMutation(UPDATE_PREPARATOIN_TIME);
    const [deletePreparationTime] = useMutation(DELETE_PREPARATOIN_TIME);

    const {
        data: preparationTimesData,
        loading: preparationTimesLoading,
        refetch: preparationTimesRefetch,
    } = useQuery(PREPARATOIN_TIMES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(PREPARATOIN_TIMES_SUBSCRIPTION, {
        onData: () => {
            preparationTimesRefetch();
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const onCreatePreparationTime = (data) => {
        addPreparationTime({
            variables: {
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };

    const onUpdatePreparationTime = (data) => {
        updatePreparationTime({
            variables: {
                updatePreparationTimeId: data.id,
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };
    const onRemovePreparationTime = (id) => {
        deletePreparationTime({
            variables: {
                deletePreparationTimeId: id,
            },
        });
    };

    if (preparationTimesLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <PreparationTimes
                preparationTimes={
                    preparationTimesData && preparationTimesData.preparationTimes
                        ? preparationTimesData.preparationTimes
                        : []
                }
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreatePreparationTime={onCreatePreparationTime}
                onUpdatePreparationTime={onUpdatePreparationTime}
                onRemovePreparationTime={onRemovePreparationTime}
            />
        </Col>
    );
}
