import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Form, Button, Card, Row, Col, Spinner, Table } from 'reactstrap';
import translate from 'translate';

export default function CuisineForm(props) {
    const { onClose, onSave, cuisine, languages } = props;

    const [order, setOrder] = useState(0);
    const [available, setAvailable] = useState(true);

    const [translations, setTranslations] = useState([]);
    const [translating, setTranslating] = useState(false);
    const [originalTranslation, setOriginalTranslation] = useState(null);

    useEffect(() => {
        if (cuisine) {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    translation:
                        cuisine.translations.find(
                            (translation) => translation.language.id === item.id
                        )?.title ?? '',
                }))
            );
            setOrder(cuisine.order);
            setAvailable(cuisine.available);
        } else {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    translation: '',
                }))
            );

            setOrder(0);
            setAvailable(true);
        }
    }, [cuisine]);

    async function getOtherTitles(original) {
        if (!original) {
            return;
        }

        let newTranslations = [...translations];

        for (let index = 0; index < translations.length; index++) {
            const element = translations[index];
            if (element.language.id !== original.language.id) {
                let translatedTitle = await translate(original.translation, {
                    from: original.language.languageCode,
                    to: element.language.languageCode,
                });
                newTranslations[index] = {
                    ...element,
                    translation: translatedTitle,
                };
            }
        }

        setTranslations(newTranslations);
        setTranslating(false);
        setOriginalTranslation(null);
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="cuisine_name">Názov</Label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Jazyk</th>
                                    <th>Preklad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {translations.map((translation) => (
                                    <tr key={translation.language.id}>
                                        <td>{`${translation.language.title} (${translation.language.languageCode})`}</td>
                                        <td>
                                            {translating &&
                                                translation.language.id !==
                                                    originalTranslation.language.id && <Spinner />}
                                            {(!translating ||
                                                translation.language.id ===
                                                    originalTranslation.language.id) && (
                                                <>
                                                    <Input
                                                        id={`cuisine_translation_for_${translation.languageCode}`}
                                                        disabled={translating}
                                                        value={translation.translation}
                                                        onChange={(e) => {
                                                            setTranslations(
                                                                translations.map((item) => {
                                                                    if (
                                                                        item.language.id ===
                                                                        translation.language.id
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            translation:
                                                                                e.target.value,
                                                                        };
                                                                    }
                                                                    return item;
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    {translation.translation.length > 0 && (
                                                        <Button
                                                            color="link"
                                                            onClick={() => {
                                                                setTranslating(true);
                                                                setOriginalTranslation(translation);
                                                                getOtherTitles(translation);
                                                            }}
                                                        >
                                                            Preložiť podľa tohto prekladu
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="cuisine_order">Poradie</Label>
                        <Input
                            id={'cuisine_order'}
                            type="number"
                            value={order}
                            onChange={(e) => {
                                setOrder(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup check>
                        <Input
                            id="cuisine_available"
                            type="checkbox"
                            checked={available}
                            onChange={() => {
                                setAvailable(!available);
                            }}
                        />
                        <Label check htmlFor="cuisine_available">
                            Zobrazovaný zákazníkom a kuchárom.
                        </Label>
                    </FormGroup>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button color="secondary" block onClick={onClose}>
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={translations.some(
                            (translation) => translation.translation.length === 0
                        )}
                        onClick={() => {
                            onSave({
                                id: cuisine?.id,
                                translations,
                                order: parseInt(order),
                                available,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
