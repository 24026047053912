import { gql } from '@apollo/client';

export const DIETS_SUBSCRIPTION = gql`
    subscription dietsSubscription {
        dietsSubscription
    }
`;

export const DIETS = gql`
    query diets($available: Boolean) {
        diets(available: $available) {
            available
            id
            order
            translations {
                title
                language {
                    id
                }
            }
        }
    }
`;

export const ADD_DIET = gql`
    mutation addDiet($available: Boolean, $order: Int, $translations: [DietTranslationInput!]!) {
        addDiet(available: $available, order: $order, translations: $translations) {
            id
        }
    }
`;

export const UPDATE_DIET = gql`
    mutation updateDiet(
        $updateDietId: Int!
        $available: Boolean
        $translations: [DietTranslationInput!]!
        $order: Int
    ) {
        updateDiet(
            id: $updateDietId
            available: $available
            translations: $translations
            order: $order
        ) {
            id
        }
    }
`;

export const DELETE_DIET = gql`
    mutation deleteDiet($deleteDietId: Int!) {
        deleteDiet(id: $deleteDietId)
    }
`;
