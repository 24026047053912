import { gql } from '@apollo/client';

export const RECIPES_SUBSCRIPTION = gql`
    subscription allRecipesSubscription {
        allRecipesSubscription
    }
`;

export const GET_RECIPES = gql`
    query recipes(
        $visible: Boolean
        $chefId: Int
        $offset: Int
        $limit: Int
        $search: String
        $languageId: Int
        $ingredients: [Int!]
    ) {
        recipes(
            visible: $visible
            chefId: $chefId
            offset: $offset
            limit: $limit
            search: $search
            languageId: $languageId
            ingredients: $ingredients
        ) {
            count
            recipes {
                id
                progress
                visible
                recipeTranslations {
                    id
                    language {
                        id
                        languageCode
                        title
                    }
                    title
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_RECIPE = gql`
    query recipe($id: Int!) {
        recipe(id: $id) {
            id
            allergens
            calories
            difficulty
            images {
                id
                order
                path
            }
            progress
            recipeIngredients {
                id
                amount
                unit
                ingredient {
                    id
                    translations {
                        language {
                            id
                        }
                        title
                    }
                }
            }
            recipeTranslations {
                id
                title
                language {
                    id
                    title
                    languageCode
                }
            }
            servings
            visible
        }
    }
`;
